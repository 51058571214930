import { Button } from 'antd';
import { styled } from 'styled-components';

export const ButtonRefreshListStyled = styled(Button)`
  background: linear-gradient(135deg, #536390, #252f64) !important;
  color: white !important;
  border: none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  margin: 0 0 0 10px !important;
  margin-top: 5px !important;
  &:hover,
  &:focus {
    background: linear-gradient(135deg, #252f64, #536390) !important;
    color: #f0f0f0 !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important;
  }
  .anticon {
    margin-right: 8px !important;
  }
`;
