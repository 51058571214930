import { message, Skeleton } from 'antd';
import AnimatedBanner from '../../components/AnimatedBanner';
import { Container } from '../../styles/GlobalStyles';
import { ActionsContainer, RefreshButtonContainer, StyledCardBancaria } from './styled';
import { ReloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { useEffect, useState } from 'react';
import ExpandedRowRender from './interfaces/expandedRowRender';
import TableComponent from '../../components/TablePagination';
import MessageHeader from '../../components/MessageHeader';
import { textBankingOFX } from './interfaces';
import ButtonRefreshList from '../../components/Common/ButtonRefreshList';
import { columnsBankingOfx } from './interfaces/columnsBankingOfx';
import UploadOFX from '../../components/UploadOfx';
import { fetchBankingOfx, fetchBankingOfxPdf, fetchBankingOfxXls } from '../../store/states/bankingOfx/actions';
import { mapFiltersBankingOfxForApi } from '../../utils/validateFilter';
import { DropDownContent } from '../../components/DropDownContent';

function BancariaOFXScreen() {
  const dispatch = useDispatch();
  const [perPage] = useState(10);
  const [tableKey, setTableKey] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [resetTableFilters, setResetTableFilters] = useState(false);
  const [showAcquirer, setShowAcquirer] = useState(() => {
    const persistedState = localStorage.getItem('showAcquirerOfxBanking');
    return persistedState ? JSON.parse(persistedState) : true;
  });

  const bankingOfx = useSelector((state: RootState) => state.bankingOfx.data);
  const bankingOfxFilters = bankingOfx.filters ?? {};

  const bankingOfxTotals = bankingOfx.totals ?? [];
  const contas = bankingOfxFilters.contas ?? [];
  const adquirentes = bankingOfxFilters.adquirentes ?? [];
  const status_conciliacao = bankingOfxFilters.status_conciliacao ?? [];
  const bankingOfxLoading = useSelector((state: RootState) => state.bankingOfx.loading);
  const loadingBankingOfxExport = useSelector((state: RootState) => state.bankingOfx.loadingBankingOfxExport);

  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);

  useEffect(() => {
    localStorage.setItem('showAcquirerOfxBanking', JSON.stringify(showAcquirer));
  }, [showAcquirer]);

  const handleExportXls = () => {
    message.success('Gerando o relatório bancário no formato .xlsx, aguarde...');
    dispatch(
      fetchBankingOfxXls({
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const handleExportPdf = () => {
    message.success('Gerando o relatório bancário no formato .pdf, aguarde...');
    dispatch(
      fetchBankingOfxPdf({
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const fetchOfxBanking = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersBankingOfxForApi(filters);
    dispatch(
      fetchBankingOfx({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField,
        sortOrder: order,
      }),
    );
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'ofxtransacaodatadeposito', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchOfxBanking(page, pageSize, sortField, sortOrder, filters);
  };

  const handleRefresh = () => {
    localStorage.removeItem('showAcquirerOfxBanking');
    setFilters({});
    setCurrentPage(1);
    setShowAcquirer(true);
    setResetTableFilters(prev => !prev);
    fetchOfxBanking(1, pageSize, 'ofxtransacaodatadeposito', 'DESC', {});
    setTableKey((prevKey) => prevKey + 1);
    setResetTableFilters(prev => !prev);
  };

  const updateFilters = (newFilters: any) => {
    setFilters(newFilters);
  };

  return (
    <AnimatedBanner>
      <Container>
        <MessageHeader textMessage={textBankingOFX} />
        <>
          <StyledCardBancaria>
            <ActionsContainer>
              <UploadOFX />
              {bankingOfx.list && bankingOfx.list.length !== 0 && <DropDownContent loadingExport={loadingBankingOfxExport} transacoes={bankingOfx} handleExportXls={handleExportXls} handleExportPdf={handleExportPdf} />}
              <RefreshButtonContainer>
                <ButtonRefreshList loading={bankingOfxLoading} icon={<ReloadOutlined />} onClick={handleRefresh} text='Limpar Filtros' />
              </RefreshButtonContainer>
            </ActionsContainer>
          </StyledCardBancaria>
          {bankingOfxLoading ? (
            <Skeleton active />
          ) : (
            <TableComponent
              key={tableKey}
              loadingData={bankingOfxLoading}
              data={bankingOfx.list && bankingOfx.list.length > 0 ? bankingOfx.list : []}
              columns={columnsBankingOfx(contas, adquirentes, status_conciliacao)}
              rowKeyId="ofxtransacaoid"
              rowRender={(record: any) => <ExpandedRowRender record={record} />}
              perPage={perPage}
              currentPage={currentPage}
              totalPages={bankingOfxTotals}
              onChangePage={handlePageChange}
              expand={true}
              externalFilters={filters}
              resetFilters={resetTableFilters}
              updateFilters={updateFilters}
              subtotais={false}
            />
          )}
        </>
      </Container>
    </AnimatedBanner>
  );
}

export default BancariaOFXScreen;
