import { Select, Modal, Button } from 'antd';
const { Option } = Select;

interface ModalPermissoesProps {
  isVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
  permissions: string[];
  onChange: (value: string[]) => void;
  userPermissions: string[];
}

export function ModalPermissoes({ isVisible, onOk, onCancel, permissions, onChange, userPermissions }: ModalPermissoesProps) {
  return (
    <Modal title="Gerenciar Permissões" visible={isVisible} onOk={onOk} onCancel={onCancel}>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="Selecione permissões"
        defaultValue={userPermissions}
        onChange={onChange}
      >
        {permissions.map(perm => (
          <Option key={perm} value={perm}>{perm}</Option>
        ))}
      </Select>
    </Modal>
  );
}
