import moment from 'moment';
import api from '../../../services/axios';
import { message } from 'antd';
import saveAs from 'file-saver';

export async function bankingOfxApi(): Promise<any> {
    const response = await api.get('/banking-ofx/all', {});
    return response.data;
}

export async function uploadOfxAPi(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    try {
        const response = await api.post('/upload-ofx', formData, {
            baseURL: process.env.REACT_APP_OFX_API,
            validateStatus: (status) => {
                return status >= 200 && status < 500;
            }
        });
        if (response.status !== 200) {
            return response;
        }
        return response;
    } catch (error) {
        console.error('Erro ao fazer o upload:', error);
        throw error;
    }
}

export async function bankingOfxTransactionsApi(company_id: number[] | number, start_date: string, end_date: string, page: number = 1, pageSize: number = 10, filters: Record<string, any> = {}, sortField: any, sortOrder: any): Promise<any> {
    const baseURL = '/banking-ofx/transacoes-all';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
        page: page.toString(),
        pageSize: pageSize.toString(),
        sortField: sortField.toString(),
        sortOrder: sortOrder.toString(),
    });
    if (Array.isArray(company_id)) {
        for (const id of company_id) {
            params.append('company_id', id.toString());
        }
    } else {
        params.append('company_id', company_id.toString());
    }
    Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if (value !== undefined && value !== null && value !== '') {
            params.append(key, value.toString());
        }
    });
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}

export async function bankingOfxXlsApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
    const baseURL = '/banking-ofx/transacoes/export/xls';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
            headers: {
                Accept: 'application/vnd.ms-excel',
            },
        });
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, 'relatorio_recebiveis.xlsx');
    } catch (error) {
        message.error(`Erro ao baixar o arquivo XLS: ${error}`);
    }
}

export async function bankingOfxPdfApi(company_id: number[] | number, start_date: string, end_date: string): Promise<void> {
    const baseURL = '/banking-ofx/transacoes/export/pdf';
    const params = new URLSearchParams({
        start_date: moment(start_date).format('YYYY-MM-DD'),
        end_date: moment(end_date).format('YYYY-MM-DD'),
    });
    const url = `${baseURL}?${params.toString()}`;
    try {
        const response = await api.get(url, {
            responseType: 'blob',
        });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, 'relatorio_recebiveis.pdf');
    } catch (error) {
        message.error(`Erro ao baixar o arquivo PDF: ${error}`);
    }
}
