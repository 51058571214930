import { Reducer } from 'redux';
import {
  FETCH_LOGS_CLOUDWATCH,
  FETCH_LOGS_CLOUDWATCH_SUCCESS,
  FETCH_LOGS_CLOUDWATCH_FAILURE,
} from './types';

export const logState = {
  data: [],
  loadingLogs: false,
  error: null
};

export const reducer: Reducer<any, any> = (state = logState, action: any) => {
  switch (action.type) {
    case FETCH_LOGS_CLOUDWATCH:
      return { ...state, loadingLogs: true };
    case FETCH_LOGS_CLOUDWATCH_SUCCESS:
      return { ...state, loadingLogs: false, data: action.payload || [] };
    case FETCH_LOGS_CLOUDWATCH_FAILURE:
      return { ...state, loadingLogs: false, error: action.payload };
    default:
      return state;
  }
};
