import { takeLatest, call, put, all } from 'redux-saga/effects';
import { logsApi } from '../../target-api/api';
import {
  fetchLogsCloudWatchSuccess,
  fetchLogsCloudWatchFailure
} from './actions';
import { FETCH_LOGS_CLOUDWATCH } from './types';

export function* fetchLogsCloudWatchSaga(action: any): Generator<any, void, any> {
  try {
    let { period, log_group_name, log_stream_name, filter_pattern } = action.payload;
    const [logsResponse] = yield all([call(logsApi, period, log_group_name, log_stream_name, filter_pattern)]);
    if (logsResponse.length > 0) {
      yield put(fetchLogsCloudWatchSuccess(logsResponse));
    }
  } catch (error: any) {
    yield put(fetchLogsCloudWatchFailure(error.message));
  }
}

export function* watchLogsCloudWatchSaga() {
  yield takeLatest(FETCH_LOGS_CLOUDWATCH, fetchLogsCloudWatchSaga);
}
