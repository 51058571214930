import {
  FETCH_LOGS_CLOUDWATCH,
  FETCH_LOGS_CLOUDWATCH_SUCCESS,
  FETCH_LOGS_CLOUDWATCH_FAILURE,
} from './types';

export const fetchLogsCloudWatch = (payload: { period: number; log_group_name: string; log_stream_name: string, filter_pattern?: string }) => ({
  type: FETCH_LOGS_CLOUDWATCH,
  payload
});

export const fetchLogsCloudWatchSuccess = (payload: any) => ({
  type: FETCH_LOGS_CLOUDWATCH_SUCCESS,
  payload
});

export const fetchLogsCloudWatchFailure = (payload: any) => ({
  type: FETCH_LOGS_CLOUDWATCH_FAILURE,
  payload
});
