import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogsCloudWatch } from '../../store/states/logs/actions';
import { RootState } from '../../store/types';
import { Select, Input, List, Typography, Row, Col } from 'antd';
import AnimatedBanner from '../../components/AnimatedBanner';
import { Container } from '../../styles/GlobalStyles';
import { StatCard } from '../../components/Charts/StatCard';
import { CloudServerOutlined, DollarCircleOutlined } from '@ant-design/icons';

type LogEntry = {
    message: string;
};

const { Option } = Select;
const { Text } = Typography;

const LogScreen: React.FC = () => {
    const dispatch = useDispatch();
    const logs = useSelector((state: RootState) => state.logs.data as LogEntry[]);
    const loadingLogs = useSelector((state: RootState) => state.logs.loadingLogs);
    const [logsData, setLogsData] = useState<LogEntry[]>([]);
    const [logGroupName, setLogGroupName] = useState("card-file-validator-prod");
    const [logStreamName, setLogStreamName] = useState("files-validation-nexxera");
    const [filterPattern, setFilterPattern] = useState("");

    useEffect(() => {
        dispatch(fetchLogsCloudWatch({
            period: 2880,
            log_group_name: logGroupName,
            log_stream_name: logStreamName,
            filter_pattern: filterPattern
        }));
    }, [logGroupName, logStreamName, filterPattern]);

    useEffect(() => {
        setLogsData(logs);
    }, [logs]);

    return (
        <AnimatedBanner>
            <Container>
                <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
                    <Col flex="5">
                        <StatCard
                            loadingData={loadingLogs}
                            title="Logs de Processamento (AWS)"
                            value={logsData.length}
                            icon={<CloudServerOutlined />}
                        />
                    </Col>
                </Row>
                <h1></h1>
                <div style={{ marginBottom: 20 }}>
                    <label>Ambiente: </label>
                    <Select defaultValue={logGroupName} style={{ width: 300 }} onChange={setLogGroupName}>
                        <Option value="card-file-validator-prod">Produção</Option>
                        <Option value="card-file-validator-homolog">Homologação</Option>
                    </Select>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <label>Microserviço: </label>
                    <Select defaultValue={logStreamName} style={{ width: 280 }} onChange={setLogStreamName}>
                        <Option value="interceptor-files">Interceptor-Files</Option>
                        <Option value="files-validation-nexxera">Files-Validation-Nex</Option>
                        <Option value="transaction-reconciliation">Transaction-Reconciliation</Option>
                        <Option value="clean-duplicity">Clean-Duplicity</Option>
                    </Select>
                </div>
                <div style={{ marginBottom: 20 }}>
                    <label>Buscar por: </label>
                    <Input type="text" value={filterPattern} onChange={e => setFilterPattern(e.target.value)} style={{ width: 290 }} />
                </div>
                <List
                    header={<div>Logs</div>}
                    bordered
                    style={{ maxHeight: '500px', overflowY: 'auto', border: '1px solid #ccc', boxShadow: '0 2px 8px rgba(0,0,0,0.1)', background: '#f0f2f5' }}
                    dataSource={logsData}
                    renderItem={item => (
                        <List.Item>
                            <Text copyable={{ text: item.message }}>{item.message}</Text>
                        </List.Item>
                    )}
                />
            </Container>
        </AnimatedBanner>
    );
};

export default LogScreen;
