import api from '../../../services/axios';

export async function logsApi(period: number, log_group_name: string, log_stream_name: string, filter_pattern: string): Promise<any> {
    const baseURL = '/logs';
    const params = new URLSearchParams({
        period: period.toString(),
        log_group_name,
        log_stream_name,
        filter_pattern
    });
    const url = `${baseURL}?${params.toString()}`;
    const response = await api.get(url);
    return response.data;
}
