export const FETCH_USUARIO = 'FETCH_USUARIO';
export const FETCH_USUARIO_SUCCESS = 'FETCH_USUARIO_SUCCESS';
export const FETCH_USUARIO_FAILURE = 'FETCH_USUARIO_FAILURE';
export const FETCH_USUARIO_PROFILE = 'FETCH_USUARIO_PROFILE';
export const FETCH_USUARIO_PROFILE_SUCCESS = 'FETCH_USUARIO_PROFILE_SUCCESS';
export const FETCH_USUARIO_PROFILE_FAILURE = 'FETCH_USUARIO_PROFILE_FAILURE';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const PROPOSTA = 'PROPOSTA';
export const PROPOSTA_SUCCESS = 'PROPOSTA_SUCCESS';
export const UPLOAD_USER_PHOTO = 'UPLOAD_USER_PHOTO';
export const FETCH_USUARIO_EVENTO = 'FETCH_USUARIO_EVENTO';
export const FETCH_USUARIO_EVENTO_SUCCESS = 'FETCH_USUARIO_EVENTO_SUCCESS';
export const FETCH_USUARIO_EVENTO_FAILURE = 'FETCH_USUARIO_EVENTO_FAILURE';
export const UPDATE_USUARIO_STATUS = 'UPDATE_USUARIO_STATUS';
export const UPDATE_USUARIO_STATUS_SUCCESS = 'UPDATE_USUARIO_STATUS_SUCCESS';
export const UPDATE_USUARIO_STATUS_FAILURE = 'UPDATE_USUARIO_STATUS_FAILURE';
export const CREATE_USUARIO = 'CREATE_USUARIO';
export const CREATE_USUARIO_SUCCESS = 'CREATE_USUARIO_SUCCESS';
export const CREATE_USUARIO_FAILURE = 'CREATE_USUARIO_FAILURE';
export const USUARIO_SYNC_EMPRESA = 'USUARIO_SYNC_EMPRESA';
export const USUARIO_SYNC_EMPRESA_SUCCESS = 'USUARIO_SYNC_EMPRESA_SUCCESS';
export const USUARIO_SYNC_EMPRESA_FAILURE = 'USUARIO_SYNC_EMPRESA_FAILURE';
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAILURE = 'FETCH_PERMISSIONS_FAILURE';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const UPDATE_USER_PERMISSIONS_SUCCESS = 'UPDATE_USER_PERMISSIONS_SUCCESS';
export const UPDATE_USER_PERMISSIONS_FAILURE = 'UPDATE_USER_PERMISSIONS_FAILURE';

