import React, { useState, useEffect } from 'react';
import { Input, Select, Form, Progress, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createUsuario } from '../../../store/states/usuario/actions';
import { RootState } from '../../../store/types';
import { StyledModal } from './styled';

interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  onCancel?: () => void;
}

const ModalCreateUser: React.FC<ModalProps> = ({ title, isOpen, onCancel }) => {
  const dispatch = useDispatch();
  const cliente = useSelector((state: RootState) => state.cliente.data);
  const [form] = Form.useForm();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => {
    if (passwordError) {
      form.setFieldsValue({ senha: form.getFieldValue('senha') });
    }
  }, [passwordError, form]);

  const calculatePasswordStrength = (password: string) => {
    let strength = 0;
    let errors = [];
    if (password.length >= 8 && password.length <= 20) {
      strength += 20;
      if (/[a-z]/.test(password)) strength += 20;
      else errors.push('Inclua pelo menos uma letra minúscula.');
      if (/[A-Z]/.test(password)) strength += 20;
      else errors.push('Inclua pelo menos uma letra maiúscula.');
      if (/[0-9]/.test(password)) strength += 20;
      else errors.push('Inclua pelo menos um número.');
      if (/[^A-Za-z0-9]/.test(password)) strength += 20;
      else errors.push('Inclua pelo menos um símbolo.');
    } else {
      errors.push('A senha deve ter entre 8 e 20 caracteres.');
    }
    setPasswordStrength(strength);
    setPasswordError(errors.join(' '));
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    form.setFieldsValue({ senha: newPassword });
    calculatePasswordStrength(newPassword);
  };

  const handleOk = () => {
    form.validateFields().then(values => {
      dispatch(createUsuario(values.nome, values.username, values.email, values.senha, values.clienteId || "1"));
      form.resetFields();
      onCancel && onCancel();
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return (
    <StyledModal title={title} visible={isOpen} onOk={handleOk} onCancel={handleCancel}>
      <Form form={form} layout="vertical">
        <Form.Item name="nome" rules={[{ required: true, message: 'Por favor, insira o nome do usuário!' }]}>
          <Input placeholder="Nome" />
        </Form.Item>
        <Form.Item name="username" rules={[{ required: true, message: 'Por favor, insira o username!' }]}>
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item name="email" rules={[{ required: true, message: 'Por favor, insira o e-mail!', type: 'email' }]}>
          <Input placeholder="E-mail" />
        </Form.Item>
        <Form.Item
          name="senha"
          rules={[
            {
              required: true,
              message: passwordError || 'Por favor, insira a senha!',
            },
          ]}
        >
          <Input.Password placeholder="Senha" onChange={handlePasswordChange} />
          <Tooltip title={passwordError} visible={!!passwordError}>
            <Progress percent={passwordStrength} showInfo={false} strokeColor={passwordStrength < 40 ? '#ff4d4f' : passwordStrength < 80 ? '#faad14' : '#52c41a'} />
          </Tooltip>
        </Form.Item>
        <Form.Item name="clienteId" rules={[{ required: true, message: 'Por favor, selecione um cliente!' }]}>
          <Select
            allowClear
            showSearch
            placeholder="Selecione o cliente"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {cliente.clientes
              .sort((a: any, b: any) => a.clientenome.localeCompare(b.clientenome))
              .map((cliente: any) => (
                <Select.Option key={cliente.clienteid} value={cliente.clienteid}>
                  {cliente.clientenome}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </StyledModal>
  );
};

export default ModalCreateUser;
