import { Reducer } from 'redux';
import {
  CREATE_USUARIO,
  CREATE_USUARIO_FAILURE,
  CREATE_USUARIO_SUCCESS,
  FETCH_USUARIO,
  FETCH_USUARIO_EVENTO,
  FETCH_USUARIO_EVENTO_FAILURE,
  FETCH_USUARIO_EVENTO_SUCCESS,
  FETCH_USUARIO_FAILURE,
  FETCH_USUARIO_PROFILE,
  FETCH_USUARIO_PROFILE_FAILURE,
  FETCH_USUARIO_PROFILE_SUCCESS,
  FETCH_USUARIO_SUCCESS,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  USUARIO_SYNC_EMPRESA,
  USUARIO_SYNC_EMPRESA_FAILURE,
  USUARIO_SYNC_EMPRESA_SUCCESS,
  UPDATE_USUARIO_STATUS,
  UPDATE_USUARIO_STATUS_FAILURE,
  UPDATE_USUARIO_STATUS_SUCCESS,
  FETCH_PERMISSIONS_SUCCESS,
  UPDATE_USER_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILURE,
  UPDATE_USER_PERMISSIONS_FAILURE
} from './types';

const initialState = {
  loading: false,
  data: {},
  error: null,
  eventos: [],
  usuarios: [],
  permissions: [],
};

export const reducer: Reducer<any> = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_USUARIO:
      return { ...state, loading: true };
    case FETCH_USUARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_USUARIO_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_USUARIO_PROFILE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USUARIO_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
        error: null,
      };
    case FETCH_USUARIO_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RECOVER_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FETCH_USUARIO_EVENTO:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USUARIO_EVENTO_SUCCESS:
      return {
        ...state,
        loading: false,
        eventos: action.payload,
      };
    case FETCH_USUARIO_EVENTO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_USUARIO_STATUS:
      return { ...state, loading: true };
    case UPDATE_USUARIO_STATUS_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case UPDATE_USUARIO_STATUS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case CREATE_USUARIO:
      return { ...state, loading: true };
    case CREATE_USUARIO_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case CREATE_USUARIO_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case USUARIO_SYNC_EMPRESA:
      return { ...state, loading: true };
    case USUARIO_SYNC_EMPRESA_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case USUARIO_SYNC_EMPRESA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_PERMISSIONS_SUCCESS:
      return { ...state, permissions: action.payload };
    case UPDATE_USER_PERMISSIONS_SUCCESS:
      return { ...state, loading: false };
    case FETCH_PERMISSIONS_FAILURE:
    case UPDATE_USER_PERMISSIONS_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
