import { takeLatest, call, put } from 'redux-saga/effects';
import {
  proposalApi,
  recoverPasswordApi,
  resetPasswordApi,
  uploadPhotoApi,
  userProfileApi,
  updateUsuarioStatusApi,
  usuarioApi,
  usuarioEventoApi,
  createUsuarioApi,
  usuarioSyncEmpresaApi
} from '../../target-api/api';
import { message } from 'antd';
import {
  fetchPermissionsFailure,
  fetchPermissionsSuccess,
  fetchUserProfileFailure,
  fetchUserProfileSuccess,
  fetchUsuario,
  fetchUsuarioEventoSuccess,
  fetchUsuarioFailure,
  fetchUsuarioSuccess,
  proposalSuccess,
  recoverPasswordSuccess,
  resetPasswordSuccess,
  updateUserPermissionsFailure,
  updateUserPermissionsSuccess
} from './actions';
import history from '../../../services/history';
import {
  CREATE_USUARIO,
  FETCH_USUARIO,
  FETCH_USUARIO_EVENTO,
  FETCH_USUARIO_PROFILE,
  PROPOSTA,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  USUARIO_SYNC_EMPRESA,
  UPDATE_USUARIO_STATUS,
  UPLOAD_USER_PHOTO,
  FETCH_PERMISSIONS,
  UPDATE_USER_PERMISSIONS
} from './types';
import { fetchPermissionsApi, updateUserPermissionsApi } from '../../target-api/endpoints/usuario';

export function* fetchUsuarioSaga() {
  try {
    const data: any[] = yield call(usuarioApi);
    if (data !== undefined) {
      yield put(fetchUsuarioSuccess(data));
    } else {
      message.warning('Falha ao retornar lista de usuários!');
    }
  } catch (error: any) {
    message.error('Falha ao buscar usuários.');
    yield put(fetchUsuarioFailure(error.toString()));
  }
}

export function* fetchUserProfileSaga(): Generator<any, void, any> {
  try {
    const response = yield call(userProfileApi);
    yield put(fetchUserProfileSuccess(response));
  } catch (error: any) {
    message.error('Falha ao buscar o perfil do usuário.');
    yield put(fetchUserProfileFailure(error.toString()));
  }
}

export function* recoverPasswordSaga(action: any) {
  try {
    const { subdomain, email } = action.payload;
    yield call(recoverPasswordApi, subdomain, email);
    yield put(recoverPasswordSuccess());
    history.push('/');
  } catch (error: any) {
    message.warning('Não foi possível enviar a recuperação de e-mail, revise os dados e tente novamente!');
    yield put(recoverPasswordSuccess());
  }
}

export function* resetPasswordSaga(action: any) {
  try {
    const { subdomain, token, password } = action.payload;
    yield call(resetPasswordApi, subdomain, token, password);
    yield put(resetPasswordSuccess());
    history.push('/');
  } catch (error: any) {
    message.warning('Não foi possível resetar a recuperação de e-mail, revise os dados e tente novamente!');
    yield put(recoverPasswordSuccess());
  }
}

export function* proposalSaga(action: any) {
  try {
    const { proposal } = action.payload;
    yield call(proposalApi, proposal);
    yield put(proposalSuccess());
  } catch (error: any) {
    message.warning('Não foi possível enviar a proposta, revise os dados e tente novamente!');
    yield put(proposalSuccess());
  }
}

export function* uploadPhotoSaga(action: any) {
  try {
    const { file } = action.payload;
    const formData = new FormData();
    formData.append('image', file.originFileObj);
    yield call(uploadPhotoApi, formData);
    message.success('Foto atualizada com sucesso!');
  } catch (error) {
    message.error('Erro ao atualizar a foto!');
  }
}

export function* fetchUsuarioEventoSaga() {
  try {
    const data: any[] = yield call(usuarioEventoApi);
    if (data !== undefined) {
      yield put(fetchUsuarioEventoSuccess(data));
    } else {
      message.warning('Falha ao retornar lista de eventos dos usuários!');
      yield put(fetchUsuarioFailure('Falha ao retornar lista de eventos dos usuários!'));
    }
  } catch (error: any) {
    message.error('Falha ao buscar eventos dos usuários.');
    yield put(fetchUsuarioFailure(error.toString()));
  }
}

export function* updateUsuarioStatusSaga(action: any) {
  try {
    const { usuarioId, status } = action.payload;
    yield call(updateUsuarioStatusApi, usuarioId, status);
    yield put(fetchUsuario());
  } catch (error: any) {
    message.error('Falha ao atualizar status do usuário.');
  }
}

export function* createUsuarioSaga(action: any) {
  try {
    const {
      nome,
      usuario,
      email,
      senha,
      clienteId
    } = action.payload;
    yield call(createUsuarioApi, nome,
      usuario,
      email,
      senha,
      clienteId);
    yield put(fetchUsuario());
  } catch (error: any) {
    message.error('Falha ao criar usuário.');
  }
}

export function* usuarioSyncEmpresaSaga(action: any) {
  try {
    const { email } = action.payload;
    yield call(usuarioSyncEmpresaApi, email);
    yield put(fetchUsuario());
  } catch (error: any) {
    message.error('Falha ao sincronizar usuário com empresa.');
  }
}

export function* fetchPermissionsSaga(): Generator<any, void, any> {
  try {
    const response = yield call(fetchPermissionsApi);
    yield put(fetchPermissionsSuccess(response));
  } catch (error) {
    yield put(fetchPermissionsFailure(error));
  }
}

export function* updateUserPermissionsSaga(action: any) {
  try {
    const { usuarioId, permissions } = action.payload;
    yield call(updateUserPermissionsApi, usuarioId, permissions);
    yield put(updateUserPermissionsSuccess());
    yield put(fetchUsuario());
    message.success('Permissões atualizadas com sucesso!');
  } catch (error) {
    yield put(updateUserPermissionsFailure(error));
    message.error('Erro ao atualizar permissões.');
  }
}

export function* watchUsuarioSaga() {
  yield takeLatest(FETCH_USUARIO, fetchUsuarioSaga);
  yield takeLatest(FETCH_USUARIO_PROFILE, fetchUserProfileSaga);
  yield takeLatest(RECOVER_PASSWORD, recoverPasswordSaga);
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(PROPOSTA, proposalSaga);
  yield takeLatest(UPLOAD_USER_PHOTO, uploadPhotoSaga);
  yield takeLatest(FETCH_USUARIO_EVENTO, fetchUsuarioEventoSaga);
  yield takeLatest(UPDATE_USUARIO_STATUS, updateUsuarioStatusSaga);
  yield takeLatest(CREATE_USUARIO, createUsuarioSaga);
  yield takeLatest(USUARIO_SYNC_EMPRESA, usuarioSyncEmpresaSaga);
  yield takeLatest(FETCH_PERMISSIONS, fetchPermissionsSaga);
  yield takeLatest(UPDATE_USER_PERMISSIONS, updateUserPermissionsSaga);
}
