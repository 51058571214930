import { call, put, takeLatest } from 'redux-saga/effects';
import { bankingOfxPdfApi, bankingOfxTransactionsApi, bankingOfxXlsApi, uploadOfxAPi, } from '../../target-api/api';
import { message } from 'antd';
import { fetchBankingOfxFailure, fetchBankingOfxPdfFailure, fetchBankingOfxPdfSuccess, fetchBankingOfxSuccess, fetchBankingOfxXlsFailure, fetchBankingOfxXlsSuccess, fetchUploadOfxFailure, fetchUploadOfxSuccess, } from './actions';
import { FETCH_BANKING_OFX_DATA, FETCH_BANKING_OFX_PDF, FETCH_BANKING_OFX_XLS, FETCH_UPLOAD_OFX_DATA } from './types';


export function* fetchBankingOfxSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const bankingOfxResponse = yield call(bankingOfxTransactionsApi, company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder);
    if (bankingOfxResponse && bankingOfxResponse.length <= 0) {
      message.warning('Nenhum registro de banking OFX encontrado!');
      yield put(fetchBankingOfxSuccess([]));
    } else {
      yield put(fetchBankingOfxSuccess(bankingOfxResponse));
    }
  } catch (error: any) {
    yield put(fetchBankingOfxFailure(error.message));
  }   
}

export function* fetchUploadOfxSaga(action: any): Generator<any, void, any> {
  const { file } = action.payload;
  try {
    const uploadOfxResponse = yield call(uploadOfxAPi, file);
    if (uploadOfxResponse.status === 200) {
      yield put(fetchUploadOfxSuccess(file, true, uploadOfxResponse.data.message));
    } else {
      yield put(fetchUploadOfxSuccess(file, false, uploadOfxResponse.data.error));
    }
  } catch (error: any) {
    yield put(fetchUploadOfxFailure(error.message, file));
  }
}

export function* fetchBankingOfxPdfSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [BankingOfxPdfResponse] = yield call(bankingOfxPdfApi, company_id, start_date, end_date);
    if (BankingOfxPdfResponse && BankingOfxPdfResponse.length > 0) {
      yield put(fetchBankingOfxPdfSuccess());
    } else {
      message.warning('Falha ao exportar lista de transações ofx em PDF!');
    }
  } catch (error: any) {
    yield put(fetchBankingOfxPdfFailure(error.message));
  }
}

export function* fetchBankingOfxXlsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [BankingOfxResponse] = yield call(bankingOfxXlsApi, company_id, start_date, end_date);
    if (BankingOfxResponse && BankingOfxResponse.length > 0) {
      yield put(fetchBankingOfxXlsSuccess());
    } else {
      message.warning('Falha ao exportar lista de transações em XLS!');
    }
  } catch (error: any) {
    yield put(fetchBankingOfxXlsFailure(error.message));
  }
}



export function* watchBankingOfxSaga() {
  yield takeLatest(FETCH_BANKING_OFX_DATA, fetchBankingOfxSaga);
  yield takeLatest(FETCH_UPLOAD_OFX_DATA, fetchUploadOfxSaga);
  yield takeLatest(FETCH_BANKING_OFX_PDF, fetchBankingOfxPdfSaga);
  yield takeLatest(FETCH_BANKING_OFX_XLS, fetchBankingOfxXlsSaga);
  
}
