import { combineReducers } from 'redux';
import { reducer as authReducer } from './states/auth/reducer';
import { reducer as clienteReducer } from './states/cliente/reducer';
import { reducer as usuarioReducer } from './states/usuario/reducer';
import { reducer as bancoReducer } from './states/banco/reducer';
import { reducer as adquirenteReducer } from './states/adquirente/reducer';
import { reducer as bandeiraReducer } from './states/bandeira/reducer';
import { reducer as empresaReducer } from './states/empresa/reducer';
import { reducer as estabelecimentoReducer } from './states/estabelecimento/reducer';
import { reducer as datasReducer } from './states/datas/reducer';
import { reducer as vendaReducer } from './states/venda/reducer';
import { reducer as pagamentoReducer } from './states/pagamento/reducer';
import { reducer as gestaoTaxaReducer } from './states/gestao-taxa/reducer';
import { reducer as modalidadeReducer } from './states/modalidade/reducer';
import { reducer as notificationReducer } from './states/notificacao/reducer';
import { reducer as agendaRecebimentos } from './states/agendaRecebimentos/reducer';
import { reducer as pagamentoConsolidado } from './states/pagamentoConsolidado/reducer'
import { reducer as bankingOfxReducer } from './states/bankingOfx/reducer';
import { reducer as importacaoArquivoReducer } from './states/importacaoArquivo/reducer';
import { reducer as logsReducer } from './states/logs/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  cliente: clienteReducer,
  banco: bancoReducer,
  adquirente: adquirenteReducer,
  bandeira: bandeiraReducer,
  modalidade: modalidadeReducer,
  empresa: empresaReducer,
  estabelecimento: estabelecimentoReducer,
  datas: datasReducer,
  venda: vendaReducer,
  pagamento: pagamentoReducer,
  usuario: usuarioReducer,
  gestaoTaxa: gestaoTaxaReducer,
  notification: notificationReducer,
  agendaRecebimentos: agendaRecebimentos,
  pagamentoConsolidado: pagamentoConsolidado,
  bankingOfx: bankingOfxReducer,
  importacaoArquivo: importacaoArquivoReducer,
  logs: logsReducer,
});
export default rootReducer;
