import React, { useEffect, useState } from 'react';
import { Card, Form, Modal, Input, Button, Row, Col, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createGestaoTaxa } from '../../../store/states/gestao-taxa/actions';
import { RootState } from '../../../store/types';
import { Select } from 'antd';
import { fetchEstabelecimento } from '../../../store/states/estabelecimento/actions';
import moment from 'moment';
const { Option } = Select;

interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const ModalRateRegister: React.FC<ModalProps> = ({ title, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const adquirente = useSelector((state: RootState) => state.adquirente.data);
  const bandeira = useSelector((state: RootState) => state.bandeira.data);
  const modalidade = useSelector((state: RootState) => state.modalidade.data);
  const empresa = useSelector((state: RootState) => state.empresa.data);
  const estabelecimento = useSelector((state: RootState) => state.estabelecimento.data);
  const [selectedEstabelecimentoAdquirenteId, setSelectedEstabelecimentoAdquirenteId] = useState<number | null>(null);
  const [selectedModalidadeId, setSelectedModalidadeId] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [form] = Form.useForm();
  const cardStyle = {
    borderRadius: '10px',
  };

  const disabledEndDate = (endValue: any) => {
    if (!endValue || !startDate) {
      return false;
    }
    return endValue.valueOf() <= startDate.valueOf();
  };

  const handleChangeEmpresa = (empresa_id: any) => {
    dispatch(fetchEstabelecimento());
  };

  const handleChangeEstabelecimento = (value: any) => {
    const selectedEstabelecimento = estabelecimento.estabelecimentos.find((estab: any) => estab.estabelecimentoid === value);
    if (selectedEstabelecimento) {
      setSelectedEstabelecimentoAdquirenteId(selectedEstabelecimento.estabelecimentoadquirenteid);
    }
  };

  const handleChangeModalidade = (value: any) => {
    setSelectedModalidadeId(value);
  };

  useEffect(() => {
    if (selectedModalidadeId !== 4) {
      form.setFieldsValue({
        quantidade_parcela: 1,
      });
    }
  }, [selectedModalidadeId]);

  useEffect(() => {
    if (selectedEstabelecimentoAdquirenteId) {
      form.setFieldsValue({
        adquirente_id: selectedEstabelecimentoAdquirenteId,
      });
    }
  }, [selectedEstabelecimentoAdquirenteId]);

  const handleClose = () => {
    form.resetFields();
    dispatch(fetchEstabelecimento());
    onClose();
  };

  const handleSave = () => {
    form.validateFields().then((formData) => {
      formData.inicio_vigencia = formData.inicio_vigencia.format('YYYY-MM-DD');
      formData.fim_vigencia = formData.fim_vigencia.format('YYYY-MM-DD');
      dispatch(createGestaoTaxa(formData));
      handleClose();
    });
  };

  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={handleClose}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancelar
        </Button>,
        <Button style={{ backgroundColor: '#252f64' }} key="save" type="primary" onClick={handleSave}>
          Salvar
        </Button>,
      ]}
      width={700}
    >
      <Card style={cardStyle}>
        <Form layout="vertical" form={form}>
          <Row gutter={[16, 16]}>
            {' '}
            <Col span={12}>
              <Form.Item name="inicio_vigencia" label="Data Início Vigência" rules={[{ required: true, message: 'Por favor, preencha a data de início da vigência!' }]}>
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" placeholder="Selecionar Data Inicial" onChange={(date) => setStartDate(date ? moment(date.format('YYYY-MM-DD')) : null)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="fim_vigencia" label="Data Fim Vigência" rules={[{ required: true, message: 'Por favor, preencha a data de fim da vigência!' }]}>
                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" placeholder="Selecionar Data Final" disabledDate={disabledEndDate} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="empresa_id" label="Empresa" rules={[{ required: true, message: 'Por favor, informe a empresa!' }]}>
                <Select showSearch placeholder="Selecione uma empresa" optionFilterProp="children" onChange={handleChangeEmpresa} filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {empresa !== undefined &&
                    empresa.empresas.map((emp: any) => (
                      <Option key={emp.empresaid} value={emp.empresaid}>
                        {emp.empresanome}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="estabelecimento_id" label="Estabelecimento" rules={[{ required: true, message: 'Por favor, informe o número do estabelecimento!' }]}>
                <Select showSearch placeholder="Selecione o estabelecimento" optionFilterProp="children" onChange={handleChangeEstabelecimento} filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {estabelecimento !== undefined &&
                    estabelecimento.estabelecimentos.map((estab: any) => (
                      <Option key={estab.estabelecimentoid} value={estab.estabelecimentoid}>
                        {`${estab.estabelecimentonumero} - ${
                          empresa.empresas.find((emp: any) => emp.empresaid === estab.estabelecimentoempresaid) ? empresa.empresas.find((emp: any) => emp.empresaid === estab.estabelecimentoempresaid).empresanome : 'Empresa não encontrada'
                        }`}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="adquirente_id" label="Adquirente" rules={[{ required: true, message: 'Por favor, informe a adquirente!' }]}>
                <Select showSearch placeholder="Selecione a adquirente" optionFilterProp="children" filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {selectedEstabelecimentoAdquirenteId &&
                    adquirente !== undefined &&
                    adquirente
                      .filter((adq: any) => adq.adquirenteid === selectedEstabelecimentoAdquirenteId)
                      .map((adq: any) => (
                        <Option key={adq.adquirenteid} value={adq.adquirenteid}>
                          {adq.adquirentenome}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="bandeira_id" label="Bandeira" rules={[{ required: true, message: 'Por favor, informe a bandeira!' }]}>
                <Select showSearch placeholder="Selecione a bandeira" optionFilterProp="children" filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {bandeira !== undefined &&
                    bandeira.map((ban: any) => (
                      <Option key={ban.bandeiraid} value={ban.bandeiraid}>
                        {ban.bandeiranome}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="modalidade_id" label="Modalidade" rules={[{ required: true, message: 'Por favor, informe a modalidade!' }]}>
                <Select showSearch placeholder="Selecione a modalidade" optionFilterProp="children" onChange={handleChangeModalidade} filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {modalidade !== undefined &&
                    modalidade.map((mod: any) => (
                      <Option key={mod.modalidadeid} value={mod.modalidadeid}>
                        {mod.modalidadenome}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="taxa_valor" label="Taxa %" rules={[{ required: true, message: 'Por favor, informe o valor da taxa!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="quantidade_parcela" label="Parcela(s)" rules={[{ required: true, message: 'Por favor, informe o número da parcela!' }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default ModalRateRegister;
